<template>
    <div class="ml-1">
        <a :href="link">
            <button type="button" class="btn btn-outline-info btn-sm">
                <i :class="'fa fa-'+ this.icon +' mr-1'"></i> <slot></slot>
            </button>
        </a>
    </div>
</template>

<script>
export default {
    props: ['link','icon'],
    data: function () {
      return {
        link: this.link,
        icon: this.icon
      }
    }
}
</script>

<style scoped>
</style>